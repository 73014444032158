import * as React from "react";
import {Link} from "gatsby";

import * as styles from "./index.module.scss";
import logo from "../../../static/new-logo.svg";
import instagramImage from "../../../static/ic-instagram.svg";
import youtubeImage from "../../../static/ic-youtoube.svg";
import facebookImage from "../../../static/ic-facebook.svg";
import SectionLayout from "../SectionLayout";
import BottomNavigationButton from "./components/BottomNavigationButton";
import SocialButtons from "./components/SocialButtons";
import ROUTES from "../../constants/routes";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <SectionLayout className={styles.footerSectionWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.logoWrapper}>
                    <img src={logo} alt="Logo"/>
                </div>
                <div className={styles.infoContainer}>
                    <div className={styles.navigationWrapper}>
                        <BottomNavigationButton title="About Us" to={ROUTES.aboutUs}/>
                    </div>
                    <div className={styles.socialsWrapper}>
                        <SocialButtons
                            imageUrl={instagramImage}
                            title="Instagram"
                            alt="Instagram"
                            to={process.env.FEARN_INSTAGRAM_LINK}
                        />
                        <SocialButtons
                            imageUrl={youtubeImage}
                            title="Youtube"
                            alt="Youtube"
                            to={process.env.FEARN_YOUTUBE_LINK}
                        />
                        <SocialButtons
                            imageUrl={facebookImage}
                            title="Facebook"
                            alt="Facebook"
                            to={process.env.FEARN_FACEBOOK_LINK}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.copyrightsWrapper}>
        <span className={styles.copyrights}>
          {`© ${currentYear} All right reserved`}
        </span>
                <div className={styles.termsPrivacyPolicyWrapper}>
                    <Link
                        className={styles.termsPrivacyPolicyButton}
                        to={ROUTES.termsAndConditions}
                    >
                        Terms of Use
                    </Link>
                    <Link
                        className={styles.termsPrivacyPolicyButton}
                        to={ROUTES.privacyPolicy}
                    >
                        Privacy Policy
                    </Link>
                </div>
            </div>
            <div className={styles.infoBlock}>
        <span>
          eBay is a trademark of eBay, Inc. This application uses the eBay API but is not endorsed or certified by eBay, Inc.<br/>
          Etsy is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.<br/>
          Poshmark is a trademark of Poshmark, Inc. This application is not endorsed or certified by Poshmark, Inc.<br/>
          Mercari is a trademark of Mercari, Inc. This application is not endorsed or certified by Mercari, Inc.<br/>
        </span>
            </div>
        </SectionLayout>
    );
};

export default Footer;
