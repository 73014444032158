import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";
import ROUTES_OUT from "../../../../constants/routesOut";

const SignInSignUp = () => {
  const linkToLogin = process.env.GATSBY_FRONT_DOMAIN
    ? process.env.GATSBY_FRONT_DOMAIN + ROUTES_OUT.login
    : "https://app.stage.fearn.co" + ROUTES_OUT.login;
  const linkToRegister = process.env.GATSBY_FRONT_DOMAIN
    ? process.env.GATSBY_FRONT_DOMAIN + ROUTES_OUT.register
    : "https://app.stage.fearn.co" + ROUTES_OUT.register;

  // const interimLink = process.env.FEARN_WAITLIST_LINK;
  const interimLink = "https://waitlist.stage.fearn.co";

  return (
    <div className={styles.signInSignUpButtonsWrapper}>
      <Link to={linkToLogin}>
        <button className={styles.signInButton} type="button">
          Sign in
        </button>
      </Link>
      <Link to={linkToRegister}>
        <button className={styles.signUpButton} type="button">
          Sign up
        </button>
      </Link>
    </div>
  );
};

export default SignInSignUp;
